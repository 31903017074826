import { FilterProvider } from '@/hooks/Filters/filter';
import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PublicTasks } from '../PublicTasks';
import { TemplateTasks } from '../TemplateTasks';
import Board from './components/Board';
import Header from './components/Header';
import { TasksProvider } from './hooks/tasks';
import { useTaskTabsNavigation } from './hooks/useTaskTabsNavigation';
import { useUserPermissions } from '@/hooks/Authentication/userPermissions';

const MonitoringContent = () => {
  return (
    <FilterProvider
      initialState={{
        status: {
          single: {
            column: 'status',
            selected: 'pending',
            table: 'tasks',
            type: 'single',
          },
        },
      }}
      screen="tasks"
    >
      <TasksProvider>
        <Flex
          flexDir="row"
          overflowY="hidden"
          alignItems="flex-start"
          gap={4}
          flex={1}
          w="full"
        >
          <Board />
        </Flex>
      </TasksProvider>
    </FilterProvider>
  );
};

const TaskIndex: React.FC = () => {
  const { checkPermission } = useUserPermissions();

  const tabs = checkPermission('rw')
    ? [
        { key: 'monitoring', content: <MonitoringContent /> },
        { key: 'public_tasks', content: <PublicTasks /> },
        { key: 'templates', content: <TemplateTasks /> },
      ]
    : [{ key: 'monitoring', content: <MonitoringContent /> }];

  const { setTab, tab } = useTaskTabsNavigation();
  const { t } = useTranslation();

  const tabIndex = tabs.findIndex((e) => e.key === tab);

  const tabWithFallback = tabIndex === -1 ? 0 : tabIndex;
  return (
    <Flex
      h="100vh"
      maxH="100vh"
      px={6}
      py={4}
      bg="gray.50"
      direction="column"
      flex={1}
    >
      <Header />
      <Tabs
        variant="enclosed"
        width="100%"
        overflow="hidden"
        maxH="100%"
        h="full"
        size="lg"
        isLazy
        lazyBehavior="keepMounted"
        index={tabWithFallback}
        onChange={(index) => {
          const item = tabs[index];
          if (item) {
            setTab(item.key);
          }
        }}
      >
        <TabList>
          {tabs.map((tabItem) => (
            <Tab
              outline="none !important"
              boxShadow="none !important"
              key={tabItem.key}
            >
              {t(`tasks.tabs.${tabItem.key}`)}
            </Tab>
          ))}
        </TabList>

        <TabPanels flex="1" h="full" pb="10" maxH="100%" w="full">
          {tabs.map((tabItem) => (
            <TabPanel
              px="0"
              height="100%"
              overflow="hidden"
              maxH="100%"
              display="flex"
              key={tabItem.key}
            >
              {tabItem.content}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

export default TaskIndex;
